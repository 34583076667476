module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/opt/build/repo/src/components/Layout.js"},"gatsbyRemarkPlugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-images","id":"d01c21c6-6090-5609-96f0-f511002acd4b","name":"gatsby-remark-images","version":"6.6.0","modulePath":"/opt/build/repo/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":1200,"linkImagesToOriginal":false,"wrapperStyle":"margin: 8px 0;"},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/opt/build/repo/node_modules/gatsby-remark-video","id":"39f47d3a-3e3f-5ab5-b851-97d45117147c","name":"gatsby-remark-video","version":"1.2.5","modulePath":"/opt/build/repo/node_modules/gatsby-remark-video/index.js","pluginOptions":{"plugins":[],"width":"100%","height":"auto","preload":"auto","muted":true,"autoplay":true,"playsinline":true,"controls":false,"loop":true},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/opt/build/repo/node_modules/gatsby-remark-copy-linked-files","id":"7c07678b-ae26-5764-9de7-0cf61a3bbb8d","name":"gatsby-remark-copy-linked-files","version":"5.7.0","modulePath":"/opt/build/repo/node_modules/gatsby-remark-copy-linked-files/index.js","pluginOptions":{"plugins":["gatsby-remark-copy-linked-files"]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"linkImagesToOriginal":false,"wrapperStyle":"margin: 8px 0;"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"pizlfilip-theme","short_name":"pizlfilip","start_url":"/","background_color":"#0D0D0D","theme_color":"#0D0D0D","display":"standalone","icon":"src/images/pizlfilip-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5cfcec8a714b6041f6e26ab2ef625a55"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
